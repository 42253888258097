import { defineNuxtPlugin } from "#imports"
import { HttpService } from "@/services"
import useConfigEnv from "@/composables/useConfigEnv"
import useCookies from "@/composables/useCookies"

export default defineNuxtPlugin(() => {
  const configEnv = useConfigEnv()
  const { cookies } = useCookies()

  const httpApi = HttpService.createHttpApi(configEnv)
  const httpAuth = HttpService.createHttpProxyAuth(configEnv)

  HttpService.addHttpApiAuthorization(httpApi, configEnv, cookies)
  HttpService.addHttpProxyAuthAuthorization(httpAuth, cookies)
  HttpService.addErrorInterceptor(httpApi, configEnv, cookies)

  return {
    provide: {
      httpApi,
      httpAuth
    }
  }
})
