import { Tracking } from '@ha/helpers'
import { defineNuxtPlugin } from "#imports";
import useConfigEnv from "@/composables/useConfigEnv";

export default defineNuxtPlugin((nuxtApp) => {
  const { SEGMENT_KEY, SEGMENT_ENABLED } = useConfigEnv();
  // we need this one only to use it for the custom directive below
  const tracking = new Tracking({
    segmentKey: SEGMENT_KEY as string,
    enabled: SEGMENT_ENABLED as boolean
  })

  nuxtApp.vueApp.directive('segment', {
    mounted(el, binding) {
      el.addEventListener('click', async (event) => {
        if (binding.value) {
          event.preventDefault()
          const el = event.currentTarget as HTMLLinkElement
          const href = el.getAttribute('href') ?? undefined
          const target = el.getAttribute('target') ?? '_self'
          const rel = el.getAttribute('rel') ?? undefined
          await tracking?.track(binding.value.name, binding.value.payload)
          window.open(href, target, rel)
        } else {
          // eslint-disable-next-line no-console
          console.error('Directive v-segment require a value like this `v-segment="{ name: \'My Event\', payload: {} }"`')
        }
      })
    }
  })
})

// export default segment
