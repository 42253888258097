import type { Not } from 'algolia-search-builder/build/types/operators'

export enum TABS {
  ALL = 'tout',
  ORGANIZATIONS = 'associations',
  ACTIVITIES = 'activites',
  PROJECTS = 'projets',
}

export enum FILTER_CATEGORY {
  CATEGORIES = 'category_tags',
  HA_TAGS = 'ha_tags',
  PARTNERS = 'partners',
  EMPTY_PARAMS = 'empty_params'
}

export enum FILTER_TAG {
  TAG_LABEL = 'tag_label',
  PARENT_LABEL = 'parent_label',
}

export enum FILTER_LOCATION {
  BBOX = 'bbox',
  CITY = 'place_city',
  DEPARTMENT = 'place_department',
  REGION = 'place_region',
  ZIPCODE = 'place_zipcode',
}

export enum FILTER_PRICE {
  IS_FREE = 'is_free',
  PRICE = 'price',
  MIN_PRICE = 'min_price',
  MAX_PRICE = 'max_price',
}

export enum FILTER_DATE {
  DATE = 'date',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
}

export enum FILTER_ACTIVITY {
  FORM_TYPE = 'form_type',
  ACTIVITY_TYPES = 'activity_type',
}

export enum ACTIVITY_FORM_TYPE {
  DONATION = 'Donation',
  CROWDFUNDING = 'Crowdfunding',
  EVENT = 'Event',
  MEMBER_SHIP = 'Membership'
}

export enum FILTER_DATE_OPTIONS {
  TODAY = 'aujourd\'hui',
  TOMORROW = 'demain',
  NEXT_WEEK_END = 'ce week-end',
  CURRENT_WEEK = 'cette semaine',
  NEXT_WEEK = 'la semaine prochaine',
  CURRENT_MONTH = 'ce mois ci',
  NEXT_MONTH = 'le mois prochain',
}

export enum FILTER_DATE_OPTIONS_KEY {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  NEXT_WEEK_END = 'NEXT_WEEK_END',
  CURRENT_WEEK = 'CURRENT_WEEK',
  NEXT_WEEK = 'NEXT_WEEK',
  CURRENT_MONTH = 'CURRENT_MONTH',
  NEXT_MONTH = 'NEXT_MONTH',
}

export interface FiltersCategory {
  // all indexes
  [FILTER_CATEGORY.EMPTY_PARAMS]?: string[]
  [FILTER_CATEGORY.CATEGORIES]?: string | string[]

  // internal tag and partners
  [FILTER_CATEGORY.HA_TAGS]?: string | string[]
  [FILTER_CATEGORY.PARTNERS]?: string | string[]
}
export interface FiltersTag {
  [FILTER_TAG.TAG_LABEL]?: string | string[]
  [FILTER_TAG.PARENT_LABEL]?: string | string[]
}

export interface FiltersLocation {
  // Geo location
  [FILTER_LOCATION.BBOX]?: [[number, number], [number, number]]
  [FILTER_LOCATION.CITY]?: string | string[]
  [FILTER_LOCATION.DEPARTMENT]?: string | string[]
  [FILTER_LOCATION.REGION]?: string | string[]
  [FILTER_LOCATION.ZIPCODE]?: string | string[]
}
export interface FiltersActivity {
  [FILTER_ACTIVITY.FORM_TYPE]?: ACTIVITY_FORM_TYPE,
  [FILTER_ACTIVITY.ACTIVITY_TYPES]?: string | string[]
}
export interface FiltersTime {
  [FILTER_DATE.DATE]?: FILTER_DATE_OPTIONS
  [FILTER_DATE.START_DATE]?: number
  [FILTER_DATE.END_DATE]?: number
}
export interface FiltersCost {
  [FILTER_PRICE.PRICE]?: [number, number]
  [FILTER_PRICE.MIN_PRICE]?: number
  [FILTER_PRICE.MAX_PRICE]?: number
  [FILTER_PRICE.IS_FREE]?: boolean
}
export interface FiltersAll {
  [FILTER_CATEGORY.EMPTY_PARAMS]?: (string | Not)[]
  [FILTER_CATEGORY.CATEGORIES]?: string | string[]
  [FILTER_CATEGORY.HA_TAGS]?: string | string[]
  [FILTER_CATEGORY.PARTNERS]?: string | string[]
  [FILTER_TAG.TAG_LABEL]?: string | (string | Not)[]
  [FILTER_TAG.PARENT_LABEL]?: string | string[]
  [FILTER_LOCATION.BBOX]?: [[number, number], [number, number]]
  [FILTER_LOCATION.CITY]?: string | (string | Not)[]
  [FILTER_LOCATION.DEPARTMENT]?: string | string[]
  [FILTER_LOCATION.REGION]?: string | string[]
  [FILTER_LOCATION.ZIPCODE]?: string | string[]
  [FILTER_ACTIVITY.ACTIVITY_TYPES]?: string | string[]
  [FILTER_ACTIVITY.FORM_TYPE]?: ACTIVITY_FORM_TYPE,
  [FILTER_DATE.DATE]?: FILTER_DATE_OPTIONS_KEY
  [FILTER_DATE.START_DATE]?: number
  [FILTER_DATE.END_DATE]?: number
  [FILTER_PRICE.IS_FREE]?: boolean
  [FILTER_PRICE.PRICE]?: [number, number]
  [FILTER_PRICE.MIN_PRICE]?: number
  [FILTER_PRICE.MAX_PRICE]?: number
}

export type Filters = FiltersAll

export type FilterKeys = keyof FiltersAll

export interface FiltersConvertRules {
  string: () => any,
  number: () => any,
  array: () => any,
  bbox: () => any,
  price: () => any,
  date: () => any,
  latlng: () => any,
}