import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45clean_45path_45global from "/app/src/middleware/redirect-clean-path.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45clean_45path_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "fetch-organization-page": () => import("/app/src/domains/association/middleware/fetch-organization-page.ts"),
  "redirect-search": () => import("/app/src/domains/search/middleware/redirect-search.ts"),
  "redirect-widget": () => import("/app/src/domains/search/middleware/redirect-widget.ts"),
  "fetch-thematic-page": () => import("/app/src/domains/thematic/middleware/fetch-thematic-page.ts"),
  "secure-iframe": () => import("/app/src/middleware/secure-iframe.ts")
}