import { defineNuxtPlugin } from '#imports'
import useConfigEnv from '@/composables/useConfigEnv'
import SearchService from '../domains/search/services/search.service.js'
import useDebug from '~/domains/utils/composables/debug.composable.js'

export default defineNuxtPlugin(() => {
  const configEnv = useConfigEnv()
  const debug = useDebug()

  // TODO - [refacto] add dedicated env for Algolia URL in CI
  const baseUrl = configEnv.NUXT_ENV_BASE_URL as string
  const url = configEnv.NUXT_ENV_ALGOLIA_BASE_URL as string || `${baseUrl.replace('https://', '')}/algolia`

  const instance = new SearchService(
    configEnv.NUXT_ENV_ENVIRONMENT as SearchService['env'],
    configEnv.NUXT_ENV_ALGOLIA_APP_ID as string,
    configEnv.NUXT_ENV_ALGOLIA_API_KEY as string,
    url,
    debug.isEnabled
  )

  return {
    provide: {
      algoliaClient: { instance, url }
    }
  }
})
