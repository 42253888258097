import { defineNuxtPlugin } from '#imports'
import useDebug from '~/domains/utils/composables/debug.composable'
import { useLogger } from '@ha/components-v3'


export default defineNuxtPlugin((nuxtApp) => {
  const debug = useDebug()
  const { datadogRum } = useLogger()

  nuxtApp.hook('vue:error', (error, instance, info) => {
    debug.warn('[nuxt-plugin] [error-handler] hook:vue:error', { error, instance, info })
    datadogRum.addError(error, {
      origin: 'hook:vue:error',
      instance,
      info
    })
  })

  nuxtApp.hook('app:error', (error) => {
    debug.warn('[nuxt-plugin] [error-handler] hook:app:error', { error })
    datadogRum.addError(error, {
      origin: 'hook:app:error'
    })
  })
})
