import type { SearchResponse } from '@algolia/client-search';
import type { ACTIVITY_FORM_TYPE } from './filters.interface'

export type AlgoliaPrefixIndex = 'dev_' | 'prod_' | 'rc_' | 'sandbox_'

export enum SEARCH_INDEX {
  ALL = 'ALL',
  ORGANIZATIONS = 'organizations',
  ACTIVITIES = 'activities',
  PROJECTS = 'projects',
}

export enum SEARCH_ALL_INDEX {
  ALL = 'ALL',
  ACTIVITIES = 'activities',
  CITIES = 'cities',
  CONTENT = 'content',
  INTERNAL_TAGS = 'internal_tags',
  ORGANIZATIONS = 'organizations',
  PROJECTS = 'projects',
  PARTNERS = 'partners',
  TAGS = 'tags',
}

export interface HitBase {
  banner: string,
  category_tags: string[]
  description: string
  ha_tags: string[]
  logo: string,
  objectID: string
  score: number
  url: string
  _highlightResult: any
}

export interface HitBaseLocationSimple extends HitBase {
  place_city: string
  place_department: string
  place_region: string
}

export interface HitBaseLocation extends HitBaseLocationSimple {
  place_address: string
  place_country: null
  place_name: null,
  place_zipcode: string
}

export interface HitFAQItem {
  id: number
  exploreContentId: number
  question: string
  answer: string
}

export interface HitSeoContent extends HitBaseLocation {
  activity_type: string
  empty_params?: string[]
  meta_description?: string
  meta_title?: string
  section1?: string
  section2?: string
  section3?: string
  section4?: string
  section5?: string
  title?: string
  faq: HitFAQItem[],
}

export interface HitTag {
  parent_id: string | number
  parent_label: string
  tag_label: string,
  tag_use_count: number,
  organizations_count: number,
  forms_count: number,
  collected_amount: number,
}

export interface HitBaseGeolocation extends HitBaseLocationSimple {
  _geoloc: {
    lat: number
    lng: number
  }
}

export interface HitBaseOrganisation extends HitBaseGeolocation {
  name: string
  last_order_date: number,
  organization_id: number,
  organization_name: string
  partners: []
}

export interface HitOrganisation extends HitBaseOrganisation {
  org_type: string
  creation_date: number,
  active_forms_count: number,
  active_forms_type: string
  active_forms_last_update_date: number,
}

export interface HitBaseForms extends HitBaseOrganisation {
  collected_amount: number,
  end_date: number,
  form_type: ACTIVITY_FORM_TYPE,
  max_price: number,
  min_price: number,
  order_count: number,
  participant_count: number,
  sale_end_date: number,
  sale_start_date: number,
  start_date: number,
  update_date: number,
}

export interface HitActivity extends HitBaseForms {
  activity_type: string
  remaining_entries: string
}

export interface HitProject extends HitBaseForms {
  goal: number
}

export type SearchHit = HitSeoContent & HitOrganisation & HitActivity & HitProject & HitTag

export type HASearchResponse = SearchResponse<SearchHit>