/**
 * # useDebug
 * Use log, warn & error, toggled with env `NUXT_DEBUG=true` (cf. `env.dev` example file).
 * @author adrien.be
 * @version 0.0.1
 */

// Get `NUXT_DEBUG` env variable and serialize it as boolean
const NUXT_DEBUG: unknown = process.env.NUXT_DEBUG
const isEnabled: boolean = NUXT_DEBUG === true || NUXT_DEBUG === 'true'

// eslint-disable-next-line no-console
if (isEnabled) console.warn('[composable] [debug] NUXT_DEBUG is enabled')

export default function useDebug() {
  const debug = {
    // share state of debug
    isEnabled,
    // eslint-disable-next-line no-console
    log: (...args) => isEnabled && console.log(...args),
    // eslint-disable-next-line no-console
    warn: (...args) => isEnabled && console.warn(...args),
    // eslint-disable-next-line no-console
    error: (...args) => isEnabled && console.error(...args),
    // eslint-disable-next-line no-console
    info: (...args) => isEnabled && console.info(...args),
    // eslint-disable-next-line no-console
    debug: (...args) => isEnabled && console.debug(...args),
  }

  return debug
}