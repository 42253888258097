export enum ConstantValues {
  accessTokenCookieName = 'tm5-HelloAsso',
  refreshTokenCookieName = 'rm5-HelloAsso',
  sessionTokenCookieName = 'sm5-HelloAsso',
  orgAdmin = 'OrganizationAdmin',
  formAdmin = 'FormAdmin'
}

export type ConstantValuesEnum = keyof typeof ConstantValues

export enum CampaignTypes {
  Event = 'Event',
  Membership = 'Membership',
  CrowdFunding = 'CrowdFunding',
  Donation = 'Donation',
  Shop = 'Shop',
  PaymentForm = 'PaymentForm'
}

export type CampaignTypesEnum = keyof typeof CampaignTypes

// TODO #18797 - MOVE TO ENV
export const facebookAppId = 164611196937215
