import { haMoment } from '@ha/helpers'
import { FILTER_DATE_OPTIONS_KEY, FILTER_PRICE, type Filters } from '../interfaces/filters.interface';
import type { And, Between, GreaterThanOrEqual, Or, SmallerThanOrEqual } from 'algolia-search-builder/build/types/operators';
import { convertParamThematicToFilter } from '~/domains/thematic/helpers/filters.helper';

export function convertStringToStringArray(values: string) {
  return values.split(',')
}

export function convertStringToNumberArray(values: string) {
  return values.split(',').map(v => parseFloat(v))
}

export function convertStringToBBox(bbox: string): [[number, number], [number, number]] {
  const values = bbox.split(',').map(v => parseFloat(v))
  return [[values[0], values[1]], [values[2], values[3]]]
}

export function convertBBoxToString(bbox: [[number, number], [number, number]]) {
  return [bbox[0][0], bbox[0][1], bbox[1][0], bbox[1][1]].join(',')
}

export function convertArrayToString(values: string[] | number[]) {
  return values.join(',')
}

export function convertArrayToOrQuery(key: string, values: string[]): Or {
  const filters = []
  for (const value of values) {
    filters.push({ [key]: typeof value == 'string' ? convertParamThematicToFilter(value) : value })
  }
  return {
    or: filters
  }
}

export function convertArrayToAndQuery(key: string, values: string[]): And {
  const filters = []
  for (const value of values) {
    filters.push({ [key]: typeof value == 'string' ? convertParamThematicToFilter(value) : value })
  }
  return {
    and: filters
  }
}

export function convertPriceToQuery(price: Filters['price']): GreaterThanOrEqual & SmallerThanOrEqual {
  if (
    Number.isInteger(price[0]) === false &&
    Number.isInteger(price[1]) === false
  ) {
    throw new Error(`Value of '${FILTER_PRICE.PRICE}' is not an integer, ${price}`,)
  }

  return {
    gte: price[0],
    lte: price[1]
  }
}

export function convertDateOptionsToQuery(option: FILTER_DATE_OPTIONS_KEY): Between {
  switch (option.toUpperCase()) {
    case FILTER_DATE_OPTIONS_KEY.TODAY:
      return {
        between: [
          haMoment().startOf('day').unix(),
          haMoment().endOf('day').unix()
        ]
      }
    case FILTER_DATE_OPTIONS_KEY.TOMORROW:
      return {
        between: [
          haMoment().startOf('day').add(1, 'day').unix(),
          haMoment().endOf('day').add(1, 'day').unix()
        ]
      }
    case FILTER_DATE_OPTIONS_KEY.NEXT_WEEK_END:
      return {
        between: [
          haMoment().endOf('week').subtract(2, 'days').subtract(6, 'hours').unix(),
          haMoment().endOf('week').unix()
        ]
      }
    case FILTER_DATE_OPTIONS_KEY.CURRENT_WEEK:
      return {
        between: [
          haMoment().startOf('week').unix(),
          haMoment().endOf('week').unix()
        ]
      }
    case FILTER_DATE_OPTIONS_KEY.NEXT_WEEK:
      return {
        between: [
          haMoment().startOf('week').add(1, 'week').unix(),
          haMoment().endOf('week').add(1, 'week').unix()
        ]
      }
    case FILTER_DATE_OPTIONS_KEY.CURRENT_MONTH:
      return {
        between: [
          haMoment().startOf('month').unix(),
          haMoment().endOf('month').unix()
        ]
      }
    case FILTER_DATE_OPTIONS_KEY.NEXT_MONTH:
      return {
        between: [
          haMoment().add(1, 'months').startOf('month').unix(),
          haMoment().add(1, 'months').endOf('month').unix()
        ]
      }
    default:
      console.warn('convertDateOptionsToQuery: option `', option, '` was not found');
      break;
  }
}