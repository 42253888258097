import { ConstantValues } from "@/constants";
import Cookies from 'universal-cookie';
import { useConfigEnv } from "#imports";
import { getCookieOptions } from '~/services/http.service';

let cookies: Cookies

export default () => {
  // Define cookie option path and domain
  const cookieOption = getCookieOptions(useConfigEnv().NUXT_ENV_BASE_URL as string)

  // Set new Cookie instance if does not exist
  cookies = cookies || new Cookies(null, cookieOption);

  // Clear unavailable Cookie
  const cookiesSnapshot = cookies.getAll()
  for (const cookieName in cookiesSnapshot) {
    if (Object.prototype.hasOwnProperty.call(cookiesSnapshot, cookieName)) {
      const cookieValue = cookiesSnapshot[cookieName];
      if (cookieValue === 'undefined' || cookieValue === '') {
        cookies.remove(cookieName, cookieOption)
      }
    }
  }

  // force dirty clean
  if (import.meta.client) {
    document.cookie.split(";").forEach(function (cookie) {
      const cookieName = cookie.split('=')[0].trim()
      const cookieValue = cookie.split('=')[1]
      if (Object.values(ConstantValues).includes(cookieName as ConstantValues)
        && (cookieValue == 'undefined' || cookieValue == '')) {
        document.cookie = cookie
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date(0).toUTCString() + ";path=/");
      }
    });
  }

  const getAccessToken = () => {
    if (import.meta.client)
      return cookies.get(ConstantValues.accessTokenCookieName);
  }

  const resetAccessToken = () => {
    if (import.meta.client)
      return cookies.remove(ConstantValues.accessTokenCookieName, cookieOption);
  }

  const resetRefreshToken = () => {
    cookies.remove(ConstantValues.refreshTokenCookieName, cookieOption);
  }

  const resetSessionToken = () => {
    cookies.remove(ConstantValues.sessionTokenCookieName, cookieOption);
  }

  const clearTokens = () => {
    resetAccessToken()
    resetRefreshToken()
    resetSessionToken()
  }

  return {
    cookies,
    cookieOption,
    getAccessToken,
    resetAccessToken,
    resetRefreshToken,
    resetSessionToken,
    clearTokens
  };
};
