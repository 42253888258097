import algoliasearch, { type SearchClient } from 'algoliasearch'
import type { MultipleQueriesQuery, MultipleQueriesOptions } from '@algolia/client-search'
import type { RequestOptions } from '@algolia/transporter'
import type { HaMultipleQueriesqueryBuilder, HaMultipleQueriesQueryUI, HaSearchOptionsBUILDER } from '../interfaces/query.interface'
import AlgoliaqueryBuilder from 'algolia-search-builder'
import { FILTER_CATEGORY, FILTER_DATE, FILTER_PRICE } from '../interfaces/filters.interface'
import { SEARCH_ALL_INDEX, type SearchHit, type AlgoliaPrefixIndex, type HASearchResponse } from '../interfaces/search.interface'
import { convertArrayToAndQuery, convertArrayToOrQuery, convertDateOptionsToQuery, convertPriceToQuery } from '../helpers/filters.helper'
import type { FieldOperations } from 'algolia-search-builder/build/types/operators'
import type { Query } from 'algolia-search-builder/build/types/query'
import { convertParamThematicToFilter } from '~/domains/thematic/helpers/filters.helper'

class SearchService {

  /**
   * Algolia Client
   * @type {SearchClient}
   * @memberof SearchService
   */
  public client: SearchClient

  /**
   * `env` is used to specify the right algolia prefix index
   * @private
   * @type {('staging' | 'prod' | 'dev' | 'rc'  | 'sandbox')}
   * @memberof SearchService
   */
  private env: 'staging' | 'prod' | 'dev' | 'rc' | 'sandbox'

  /**
   * Hits per page by default
   * @private
   * @memberof SearchService
   */
  private defaultHitsPerPage = 30

  /**
   * Hits page by default
   * @private
   * @memberof SearchService
   */
  private defaultPage = 0

  private debug = false

  /**
   * clien is fetching
   * @memberof SearchService
   */
  _isFetching = false
  get isFetching() {
    return this._isFetching
  }
  set isFetching(value: boolean) {
    this.log('fetching is:', value)
    this._isFetching = value
  }

  constructor(env: SearchService['env'], algoliaAppId: string, algoliaApIKey: string, url: string, debug = false) {
    this.env = env
    this.debug = debug
    this.client = algoliasearch(
      algoliaAppId,
      algoliaApIKey,
      {
        headers: { referer: import.meta.server ? url : '' },
        hosts: [{ url }],
        timeouts: {
          connect: 60,
          read: 5,
          write: 30
        }
      }
    )
  }

  async multipleQueries(queries: MultipleQueriesQuery[], requestOption?: RequestOptions | MultipleQueriesOptions) {
    this.log('multipleQueries')
    if (this.isFetching) {
      this.log('multipleQueries > canceled because process already running')
      return
    }
    try {
      this.isFetching = true
      const response = await this.client.multipleQueries<SearchHit>(queries, requestOption)
      const { results } = response
      return results as HASearchResponse[]
    } catch (error) {
      this.error('multipleQueries', error.status, error.message, JSON.stringify(error.transporterStackTrace))
      throw error
    } finally {
      this.isFetching = false
    }
  }

  get prefixIndexes(): AlgoliaPrefixIndex {
    switch (this.env) {
      // dev
      case 'dev':
        return 'dev_'
      // rc
      case 'rc':
        return 'rc_'
      // sandbox
      case 'sandbox':
        return 'sandbox_'
      // prod & staging
      case 'prod':
      case 'staging':
        return 'prod_'
    }
  }

  get indexes(): Record<SEARCH_ALL_INDEX, string> {
    return {
      [SEARCH_ALL_INDEX.ALL]: SEARCH_ALL_INDEX.ALL,
      [SEARCH_ALL_INDEX.ACTIVITIES]: `${this.prefixIndexes}${SEARCH_ALL_INDEX.ACTIVITIES}`,
      [SEARCH_ALL_INDEX.CITIES]: `${this.prefixIndexes}${SEARCH_ALL_INDEX.CITIES}`,
      [SEARCH_ALL_INDEX.CONTENT]: `${this.prefixIndexes}${SEARCH_ALL_INDEX.CONTENT}`,
      [SEARCH_ALL_INDEX.INTERNAL_TAGS]: `${this.prefixIndexes}${SEARCH_ALL_INDEX.INTERNAL_TAGS}`,
      [SEARCH_ALL_INDEX.ORGANIZATIONS]: `${this.prefixIndexes}${SEARCH_ALL_INDEX.ORGANIZATIONS}`,
      [SEARCH_ALL_INDEX.PARTNERS]: `${this.prefixIndexes}${SEARCH_ALL_INDEX.PARTNERS}`,
      [SEARCH_ALL_INDEX.PROJECTS]: `${this.prefixIndexes}${SEARCH_ALL_INDEX.PROJECTS}`,
      [SEARCH_ALL_INDEX.TAGS]: `${this.prefixIndexes}${SEARCH_ALL_INDEX.TAGS}`
    }
  }


  /**
   * 
   * @param queries 
   * @returns 
   */
  queryUiToBuilder(queries: HaMultipleQueriesQueryUI[]): HaMultipleQueriesqueryBuilder[] {
    const queriesBuilder: HaMultipleQueriesqueryBuilder[] = []

    for (const query of queries) {

      if (!query) {
        console.warn('algolia.service.queryUiToBuilder > query is not valid.', query)
        continue
      }

      // check if indexName exist in indexes list
      if (!this.indexes[query.indexName]) {
        throw new Error(`algolia index: '${query.indexName}' is not valid`)
      }

      // create empty filters Object 
      const filters: HaSearchOptionsBUILDER['filters'] = { and: [] }

      // format each filter
      for (const keyFilter in query.params.filters) {
        if (Object.prototype.hasOwnProperty.call(query.params.filters, keyFilter)) {
          // get value of query filter
          const valueFilter = query.params.filters[keyFilter]

          if (valueFilter === undefined || valueFilter === null) {
            // eslint-disable-next-line no-console
            console.warn('keyFilter', keyFilter, 'is `null` or `undefined`, it was skipped')
            continue
          }

          // convert UI filter to BUILDER filter format
          // Date
          if (keyFilter === FILTER_DATE.DATE) {
            const start_date = convertDateOptionsToQuery(valueFilter)
            filters.and.push({ start_date })
          }

          // Price filter
          else if (keyFilter === FILTER_PRICE.PRICE) {
            const { gte, lte } = convertPriceToQuery(valueFilter)
            filters.and.push({ min_price: { gte } })
            filters.and.push({ max_price: { lte } })
          }

          else if (keyFilter === FILTER_CATEGORY.EMPTY_PARAMS) {
            const { and } = convertArrayToAndQuery(keyFilter, valueFilter)
            for (const value of and) {
              if (value) {
                filters.and.push(value)
              }
            }
          }

          // Start Date
          else if (keyFilter === FILTER_DATE.START_DATE) {
            const start_date: FieldOperations = { gte: valueFilter }
            filters.and.push({ start_date })
          }

          // End Date
          else if (keyFilter === FILTER_DATE.END_DATE) {
            const end_date: FieldOperations = { lte: valueFilter }
            filters.and.push({ end_date })
          }

          // generic type
          // ----

          // String
          else if (typeof valueFilter === 'string') {
            filters.and.push({ [keyFilter]: convertParamThematicToFilter(valueFilter) })
          }

          // String
          else if (typeof valueFilter === 'number') {
            filters.and.push({ [keyFilter]: valueFilter })
          }

          // Array
          else if (Array.isArray(valueFilter) && valueFilter.length) {
            const { or } = convertArrayToOrQuery(keyFilter, valueFilter)
            filters.and.push({ or })
          }

          // Object 
          else if (typeof valueFilter === 'object' && Object.values(valueFilter).length) {
            const { or } = convertArrayToOrQuery(keyFilter, Object.values(valueFilter))
            filters.and.push({ or })
          }
        }
      }

      if(filters.and.length === 0) {
        delete filters.and
      }

      // prepare query
      const queryBuilder: HaMultipleQueriesqueryBuilder = {
        // set indexName with prefix
        indexName: this.indexes[query.indexName],
        // set query
        query: query.query,
        // set `params` or set default values
        params: {
          ...query.params,
          // set `hitsPerPage` or set default value of service 
          hitsPerPage: query.params.hitsPerPage || this.defaultHitsPerPage,
          // set `page` or set by default 
          page: query.params.page || this.defaultPage,
          // set prepared filters
          filters
        }
      }

      queriesBuilder.push(queryBuilder)
    }

    return queriesBuilder
  }

  /**
   * Convert searchable list into an algolia standard queries list
   * @param {Array} queries a list of searchable configurations
   * @returns {Array} Standard Algolia query list
   */
  queryBuilderToAlgolia(queries: HaMultipleQueriesqueryBuilder[]): MultipleQueriesQuery[] {

    // Prepare return searchable
    const queriesAlgolia: MultipleQueriesQuery[] = []

    // Clean & build filters for each searchable 
    for (const query of queries) {

      // Build filters
      const filters = new AlgoliaqueryBuilder(query.params.filters as Query).exec()

      // Build Query
      const queryAlgolia: MultipleQueriesQuery = {
        ...query,
        type: 'default',
        params: {
          ...query.params,
          filters
        }
      }

      // Push it to prepare return
      queriesAlgolia.push(queryAlgolia)
    }

    return queriesAlgolia
  }

  /**
   * Algolai fetch multiple queries
   * @param queries 
   * @returns 
   */
  async fetch(queries: HaMultipleQueriesQueryUI[]) {
    try {
      this.log('> fetch')
      this.log('> fetch > queries ui', JSON.stringify(queries))
      const queriesBuilder = this.queryUiToBuilder(queries)
      this.log('> fetch > queries builder', JSON.stringify(queriesBuilder))
      const queriesAlgolia = this.queryBuilderToAlgolia(queriesBuilder)
      this.log('> fetch > queries algolia', JSON.stringify(queriesAlgolia))
      const results = await this.multipleQueries(queriesAlgolia)
      this.log('> fetch > results', results?.map(res => res?.hitsPerPage), results?.map(res => res?.nbHits))
      return results
    } catch (error) {
      this.error(error)
      throw error
    }
  }

  log(...args) {
    if (this.debug === false) return
    // eslint-disable-next-line no-console
    console.info('[search-service]', ...args)
  }

  error(...args) {
    if (this.debug === false) return
    // eslint-disable-next-line no-console
    console.error('[search-service][error]', ...args)
  }

}

export default SearchService
