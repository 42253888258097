<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Calque_1"
    viewBox="0 0 200 43.5"
  >
    <g class="brand">
      <path
        d="M71.1 19.3v13.3h-6.6v-12c0-1.4-.4-1.8-1-1.8-.7 0-1.5.6-2.2 1.8v12h-6.6v-25l6.6-.7v9.4c1.5-1.6 3-2.3 5-2.3 3-.1 4.8 1.9 4.8 5.3zM90.3 25.5H79.6c.4 2.6 1.6 3 3.6 3 1.3 0 2.5-.5 4-1.6l2.7 3.7c-2 1.7-4.7 2.7-7.3 2.7-6.5 0-9.6-4.1-9.6-9.6 0-5.3 3-9.7 8.9-9.7 5.2 0 8.7 3.4 8.7 9.4-.1.5-.2 1.4-.3 2.1zm-6.3-4c0-1.8-.4-3.3-2.1-3.3-1.4 0-2.1.8-2.4 3.6H84v-.3zM92.1 27.4V7.5l6.6-.7v20.3c0 .6.3.9.8.9.2 0 .5 0 .7-.2l1.2 4.7c-1.2.4-2.4.6-3.6.6-3.7.2-5.7-2-5.7-5.7zM102.1 27.4V7.5l6.6-.7v20.3c0 .6.3.9.8.9.2 0 .5 0 .7-.2l1.2 4.7c-1.2.4-2.4.6-3.6.6-3.7.2-5.7-2-5.7-5.7zM129.4 23.6c0 5.9-3.5 9.6-9.2 9.6-5.6 0-9.2-3.4-9.2-9.7 0-5.9 3.5-9.6 9.2-9.6 5.6 0 9.2 3.5 9.2 9.7zm-11.5 0c0 3.6.7 4.9 2.4 4.9 1.6 0 2.4-1.4 2.4-4.9 0-3.6-.7-4.9-2.4-4.9s-2.5 1.5-2.4 4.9zM147.8 28.9l-1.3 4.3c-2.3-.2-3.8-.8-4.8-2.5-1.3 2-3.3 2.6-5.4 2.6-3.6 0-5.9-2.4-5.9-5.7 0-4 3-6.2 8.6-6.2h1.3v-.5c0-1.8-.6-2.3-2.6-2.3-1.6.1-3.1.4-4.6.9l-1.4-4.2c2.2-.9 4.6-1.4 7-1.4 5.7 0 8 2.2 8 6.6v6.2c0 1.3.3 1.9 1.1 2.2zm-7.5-1.4v-2.7h-.7c-1.9 0-2.7.6-2.7 2 0 1 .6 1.7 1.5 1.7.7.1 1.5-.3 1.9-1zM163.6 16.3l-2.3 3.6c-1.3-.8-2.7-1.3-4.2-1.3-1.1 0-1.5.3-1.5.8 0 .6.2.9 3.6 1.9 3.4 1.1 5.2 2.5 5.2 5.8 0 3.7-3.5 6.2-8.4 6.2-3.1 0-6-1.1-7.8-2.9l3.1-3.5c1.3 1 2.9 1.8 4.5 1.8 1.2 0 1.9-.4 1.9-1.1 0-.9-.4-1.1-3.4-2-3.3-1-5.2-2.9-5.2-5.8 0-3.2 2.8-5.8 7.7-5.8 2.6-.1 5.2.8 6.8 2.3zM180.1 16.3l-2.3 3.6c-1.3-.8-2.7-1.3-4.2-1.3-1.1 0-1.5.3-1.5.8 0 .6.2.9 3.6 1.9 3.4 1.1 5.2 2.5 5.2 5.8 0 3.7-3.5 6.2-8.4 6.2-3.1 0-6-1.1-7.8-2.9l3.1-3.5c1.3 1 2.9 1.8 4.5 1.8 1.2 0 1.9-.4 1.9-1.1 0-.9-.4-1.1-3.4-2-3.3-1-5.2-2.9-5.2-5.8 0-3.2 2.8-5.8 7.7-5.8 2.6-.1 5.1.8 6.8 2.3zM200 23.6c0 5.9-3.5 9.6-9.2 9.6-5.6 0-9.2-3.4-9.2-9.7 0-5.9 3.5-9.6 9.2-9.6 5.6 0 9.2 3.5 9.2 9.7zm-11.5 0c0 3.6.7 4.9 2.4 4.9 1.6 0 2.4-1.4 2.4-4.9 0-3.6-.7-4.9-2.4-4.9s-2.5 1.5-2.4 4.9z"
      />
    </g>

    <g class="icon">
      <g>
        <linearGradient
          id="SVGID_1_"
          x1="4.322"
          x2="24.268"
          y1="33.651"
          y2="-.503"
          gradientTransform="matrix(1 0 0 -1 0 44.736)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0"
            stop-color="#498a63"
          />
          <stop
            offset=".25"
            stop-color="#61b984"
          />
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="M12.9 34.9c-6.6-7.6-2.2-26.8.6-26.8C8.1 7.9-1.1 11.5.2 24.4c1.5 12 12.3 20.4 24.1 18.9 3.8-.5 7.3-2 10.3-4.3-10.4 7.5-17.4.8-21.7-4.1z"
        />
      </g>

      <g>
        <linearGradient
          id="SVGID_2_"
          x1="19.889"
          x2="40.524"
          y1="3.627"
          y2="36.697"
          gradientTransform="matrix(1 0 0 -1 0 44.736)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0"
            stop-color="#89356d"
          />
          <stop
            offset=".21"
            stop-color="#b94794"
          />
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M37.2 21.9C31.7 33 14.8 37.7 12.9 34.8c3.3 4.9 11.5 11.6 21.8 4 9.4-7.3 11.1-21 3.8-30.5-2.3-3-5.4-5.3-8.9-6.8 11.7 5.3 10.5 14.6 7.6 20.4z"
        />
      </g>

      <g>
        <linearGradient
          id="SVGID_3_"
          x1="3.242"
          x2="37.689"
          y1="35.782"
          y2="23.384"
          gradientTransform="matrix(1 0 0 -1 0 44.736)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset=".6"
            stop-color="#f59c1c"
          />
          <stop
            offset="1"
            stop-color="#c7702b"
          />
        </linearGradient>
        <path
          fill="url(#SVGID_3_)"
          d="M13.5 8.1c11.9-1.3 25.4 11 23.7 13.9 3.3-5.8 4.1-15.1-7.5-20.4C18.6-2.9 6 2.5 1.6 13.7.2 17.2-.3 21 .2 24.7-.6 11.9 9.1 8.5 13.5 8.1z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
svg {
  max-width: 8em;
  opacity: 0.25;
}

.brand {
  fill: #2e2f5e;
  fill-opacity: 1;
  stroke: #2e2f5e;
  stroke-opacity: 1;
  stroke-width: 0.5px;
  stroke-dasharray: 100px;
  stroke-dashoffset: 0;
  will-change: transform, fill-opacity, stroke-dashoffset;
  animation: fadeIn 1.6s 1 ease-in-out, slideIn 2s 1 ease 1.2s;
  animation-fill-mode: forwards;
  transform: translateX(-10%);
}

.icon {
  will-change: transform, fill-opacity;
  animation: rotate 2s 1 ease 1.6s;
  animation-fill-mode: forwards;
  transform-origin: 21.5px 21.5px;
  fill-opacity: 0;
}


@keyframes slideIn {
  0% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes fadeIn {
  0% {
    fill-opacity: 0;
    stroke-dashoffset: 100px;
  }

  60% {
    fill-opacity: 0;
  }

  100% {
    fill-opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-16deg) scale(0.95);
    fill-opacity: 0;
  }

  100% {
    transform: rotate(0deg) scale(1);
    fill-opacity: 1;
  }
}
</style>
