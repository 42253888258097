// import https from 'https'
import type Cookies from 'universal-cookie';
import type { ConfigEnv } from "@/composables/useConfigEnv";
import type { AxiosResponse } from "axios";
import axios, { AxiosError } from "axios";
import { getJwtInfo } from "./auth.service";
import { ConstantValues } from '@/constants'
import useDebug from '~/domains/utils/composables/debug.composable';
import { isValidJSON } from '~/helpers';

const { accessTokenCookieName, refreshTokenCookieName, sessionTokenCookieName } = ConstantValues
const interceptorResponse = (response) => response;
const interceptorError = async (error) => await Promise.reject(error);
const debug = useDebug()

/**
 * Get url of nuxt's serveur
 * @returns {String}
 */
/* We have to prefix all server middleware url by '/forms/' otherwise ha-proxy make bad redirection */
const getProxyBaseURL = (configEnv: ConfigEnv): string => `${configEnv.NUXT_ENV_BASE_URL}/forms`;

/**
 * Create https agent
 * @returns {Agent}
 */
// const getHttpsAgent = (configEnv: ConfigEnv) => {
//   return new https.Agent({
//     rejectUnauthorized: configEnv.NODE_ENV !== 'development'
//   })
// }

/**
 * Create axios instance for oauth2
 * We use it only in nuxt's server to obfuscate api and client's id
 * @returns {AxiosInstance}
 */
const createHttpAuth = (configEnv: ConfigEnv) => {
  const httpAuth = axios.create({
    baseURL: configEnv.NUXT_ENV_AUTH_URL as string,
    withCredentials: true,
    headers: {
      'Origin': 'www.helloasso-dev.com',
      'Referer': 'www.helloasso-dev.com',
      // 'Access-Control-Allow-Origin': 'www.helloasso-dev.com',
      'Access-Control-Allow-Origin': '*'
    }
  });
  return httpAuth;
};

/**
 * Create axios instance for api v5
 * @returns {AxiosInstance}
 */
const createHttpApi = (configEnv: ConfigEnv) => {
  const httpApi = axios.create({
    withCredentials: true,
    // httpsAgent: getHttpsAgent(configEnv),
    baseURL: configEnv.NUXT_ENV_BASE_API as string,
    // headers: {
      // 'Access-Control-Allow-Origin': 'www.helloasso-dev.com'
    // }
  });
  return httpApi;
};

/**
 * Create axios instance for auth proxy
 * We use it to make oauth2 calls in nuxt's server and obfuscate api and client's id
 * @returns {AxiosInstance}
 */
const createHttpProxyAuth = (configEnv: ConfigEnv) => {
  const httpProxyAuth = axios.create({
    withCredentials: true,
    // httpsAgent: getHttpsAgent(configEnv),
    baseURL: `${getProxyBaseURL(configEnv)}/auth`,
  });
  httpProxyAuth.interceptors.response.use(
    interceptorResponse,
    interceptorError
  );
  return httpProxyAuth;
};

/**
 * Create axios instance for api proxy
 * We use it to retry api calls in nuxt's server in case of network error
 * @returns {AxiosInstance}
 */
const createHttpProxyApi = (configEnv: ConfigEnv) => {
  const httpProxy = axios.create({
    withCredentials: false,
    // httpsAgent: getHttpsAgent(configEnv),
    baseURL: getProxyBaseURL(configEnv),
  });
  httpProxy.interceptors.response.use(interceptorResponse, interceptorError);
  return httpProxy;
};

// Cookie defie path and domain options
const getCookieOptions = (baseUrl: string) => {
  return ({
    path: '/',
    domain: baseUrl?.replace(/https?:\/\/(www|local)/g, ''),
  })
} 

/**
 * Makes the request to get token data
 * Made to easily keep a token instance or start a new one if needed
 * @param {[{refreshToken: string}]} payload - leave empty to request a brand new token
 * @returns {{access_token: string, request_token: string, exp: number}}
 */
const fetchToken = async (
  configEnv: ConfigEnv,
  $cookies: Cookies,
  payload?: { refreshToken: string },
) => {
  debug.log('[http-service][auth] Fetch Token');
  const httpProxyAuth = createHttpProxyAuth(configEnv);
  const response = await httpProxyAuth.post("/token", payload);
  
  const cookieOptions = getCookieOptions(configEnv.NUXT_ENV_BASE_URL as string)
  
  console.warn('>>>> FETCH TOKEN', cookieOptions);
  
  // Overide refresh token
  const refreshTokenCookieValue = getCookieFormResponse(response, refreshTokenCookieName)
  if(refreshTokenCookieValue) {
    $cookies.remove(refreshTokenCookieName, cookieOptions)
    $cookies.set(refreshTokenCookieName, refreshTokenCookieValue, cookieOptions)
  }
  // Overide access token
  const accessTokenCookieValue = getCookieFormResponse(response, accessTokenCookieName)
  if(accessTokenCookieValue) {
    $cookies.remove(accessTokenCookieName, cookieOptions)
    $cookies.set(accessTokenCookieName, accessTokenCookieValue, cookieOptions)
  }
  // Overide session token
  const sessionTokenCookieValue = getCookieFormResponse(response, sessionTokenCookieName)
  if(sessionTokenCookieValue) {
    $cookies.remove(sessionTokenCookieName, cookieOptions)
    $cookies.set(sessionTokenCookieName, sessionTokenCookieValue, cookieOptions)
  }
  return response.data;
};

const getCookieFormResponse = (response: AxiosResponse, cookieName: string) => {
  return (response.headers?.['set-cookie'] as string[])
    ?.find(cookie => cookie.includes(cookieName))
    ?.match(new RegExp(`^${cookieName}=(.+?);`))
    ?.[1];
}

/**
 * Catch 401 errors
 * Get a brand new access_token
 * Retry the failed request with the new access_token
 * This interceptor is a fallback if a user tries to access a page with an expired access_token + an expired refresh_token
 * @param {AxiosInstance} httpApi
 */
const httpForceAuthentication = async (configEnv: ConfigEnv, $cookies: Cookies, error) => {
  debug.warn(`[http-service][auth] force`)
  const { access_token } = await fetchToken(configEnv, $cookies);
  const { url, method, data, headers: previousResponseHeaders } = error.config;
  const Authorization = `Bearer ${access_token}`;

  return await createHttpApi(configEnv)({
    url,
    method,
    data,
    headers: { ...previousResponseHeaders, Authorization },
  });
};
/**
 * Add response interceptor to httpApi to retry request in network error in nuxt's server with httpProxyApi
 * @param {AxiosInstance} httpApi
 * @returns {Object} config
 */
const httpApiRetry = async (configEnv: ConfigEnv, error) => {
  const httpProxyApi = createHttpProxyApi(configEnv);
  /*
      In case of safari network error we have to use server middleware to send request from server side
      Ticket: https://dev.azure.com/helloasso/HelloAsso/_workitems/edit/16556
  */
  const request = error?.config;
  const { url, method, data, headers } = request;
  if(!url) throw 'Url is undefined'
  return await httpProxyApi({
    method: "post",
    data: { url, method, data, headers },
    url: "/api",
  });
};

/**
 * Get Bearer Authorization from existing access cookie
 * @param {$cookies} $cookies
 * @returns {String}
 */
const getValidCookie = ($cookies, cookieName: string) => {
  const cookieToken = $cookies.get(cookieName);
  const jwtInfo = getJwtInfo(cookieToken)
  if (!cookieToken) {
    debug.warn(`[http-service][cookie] '${cookieName}' is undefined`);
    return null
  } else if (jwtInfo?.isExpired === true) {
    debug.warn(`[http-service][cookie] '${cookieName}' was espired`);
    return null
  } else if (jwtInfo?.isExpired === false) {
    debug.log(`[http-service][cookie] '${cookieName}' is valid`);
    return `Bearer ${cookieToken}`
  }
};

/**
 * Get Bearer Authorization from existing and new access cookie
 * @param {$cookies} $cookies
 * @returns {Promise<String>}
 */
const getAuthorizationBearer = async (configEnv: ConfigEnv, $cookies) => {
  const bearerToken = getValidCookie($cookies, accessTokenCookieName);
  if (bearerToken) {
    debug.log('[http-service][auth] Bearer Token finded');
    return bearerToken;
  }

  const refreshToken = getValidCookie($cookies, accessTokenCookieName);
  const { access_token } = await fetchToken(
    configEnv,
    $cookies,
    refreshToken ? { refreshToken } : undefined
  );

  return `Bearer ${access_token}`;
};

/**
 * Add request interceptor to httpProxyAuth to add Bearer Authorization to headers
 * @param {AxiosInstance} httpProxyAuth
 * @param {$cookies} $cookies
 * @returns {Object} config
 */
const addHttpProxyAuthAuthorization = (httpProxyAuth, $cookies) => {
  httpProxyAuth.interceptors.request.use((config) => {
    const { headers } = config;
    const bearerToken = getValidCookie($cookies, accessTokenCookieName);
    if (bearerToken) {
      headers.Authorization = bearerToken;
    }
    return { ...config, headers };
  });
};

/**
 * Add request interceptor to httpApi to add Bearer Authorization to headers
 * @param {AxiosInstance} httpApi
 * @param {$cookies} $cookies
 * @returns {Object} config
 */
const addHttpApiAuthorization = (httpApi, configEnv: ConfigEnv, $cookies) => {
  httpApi.interceptors.request.use(async (config) => {
    debug.log(`[http-service][${config.method}] ${config.url}`)
    const { headers } = config;
    const authorizationBearer = await getAuthorizationBearer(
      configEnv,
      $cookies
    );
    if (authorizationBearer) {
      headers.Authorization = authorizationBearer;
    }
    return {
      ...config,
      headers,
    };
  });
};

const addErrorInterceptor = (httpApi, configEnv: ConfigEnv, $cookies: Cookies) => {
  httpApi.interceptors.response.use(interceptorResponse, async (error) => {
    if (error?.response?.status === 401) {
      return await httpForceAuthentication(configEnv, $cookies, error);
    }
    if (error?.message === "Network Error") {
      const res = await httpApiRetry(configEnv, error);
      if(isValidJSON(res.data) == false) throw new AxiosError('Response data is not a JSON', '500')
      return res
    }
    return await interceptorError(error);
  });
};

export {
  getCookieOptions,
  createHttpAuth,
  createHttpProxyApi,
  createHttpApi,
  createHttpProxyAuth,
  addHttpApiAuthorization,
  addHttpProxyAuthAuthorization,
  addErrorInterceptor,
};
