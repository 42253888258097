import { defineNuxtRouteMiddleware, navigateTo } from '#imports'
import { functions } from '@ha/helpers'

const matchCaret = /\/--*|--*\/--*|--*\/|--*$/g

export const pathIsExplore = (path: string): boolean =>
  !!path.startsWith('/e/')
export const pathContainCaret = (path: string): boolean =>
  !!path.match(matchCaret)?.length
export const pathReplaceCaret = (path: string): string =>
  path.replace(matchCaret, '/')

export default defineNuxtRouteMiddleware((to) => {
  const { path, query, hash } = to

  // 0. Normalize (and sanitize) URL with global common rules.
  let nextPath: string = functions.getNormalizedUrl(path)

  // 1. Remove dirty char `-` before and after url params.
  if (pathContainCaret(nextPath) && pathIsExplore(nextPath)) {
    nextPath = pathReplaceCaret(nextPath)
  }

  // 2. Remove `/` at end of url.
  if (nextPath !== '/' && nextPath.endsWith('/')) {
    nextPath = nextPath.replace(/\/+$/, '') || '/'
  }

  // 3. If nextPath is different from the original path, redirect with code 301.
  if (nextPath !== path) {
    const nextRoute = { path: nextPath, query, hash }
    return navigateTo(nextRoute, { redirectCode: 301 })
  }
})
