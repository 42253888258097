import { pluginTracking, pluginLogger, pluginVeeValidate } from "@ha/components-v3"
import { defineNuxtPlugin } from "#imports"
import useConfigEnv from "@/composables/useConfigEnv"

export default defineNuxtPlugin((nuxtApp) => {
  const {
    SEGMENT_KEY,
    SEGMENT_ENABLED,
    DD_ENV,
    DD_RUM_CLIENT_TOKEN,
    DD_RUM_APP_ID,
    DD_RUM_SESSION_SAMPLE_RATE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
    DD_NUXT_ENV_TIMESTAMP,
    DD_SERVICE,
    DD_SITE
  } = useConfigEnv()

  pluginVeeValidate()

  pluginTracking(nuxtApp, {
    SEGMENT_KEY,
    SEGMENT_ENABLED,
  })

  pluginLogger(nuxtApp, {
    client: {
      DD_ENV,
      DD_RUM_CLIENT_TOKEN,
      DD_RUM_SESSION_SAMPLE_RATE,
      DD_SERVICE,
      DD_SITE
    },
    session: {
      DD_RUM_APP_ID,
      DD_RUM_CLIENT_TOKEN,
      DD_SITE,
      DD_SERVICE,
      DD_ENV,
      DD_RUM_SESSION_SAMPLE_RATE,
      DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
      DD_NUXT_ENV_TIMESTAMP,
      experimentalFeatures: ['feature_flags']
    }
  })
})
